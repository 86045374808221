@import '@/modules/ui/utils/mixins';

.form {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(32px);
}

.namesRow {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(32px);

  @include medium-2-up {
    flex-direction: row;
    gap: px-to-rem(16px);
  }
}
